import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WirUeberUnsSubmenu from "../../components/wirueberuns/wirueberuns_submenu"
import TermineMargin from "../../components/termine-margin"
import WirUeberUnsContentHeader from "../../components/wirueberuns/wirueberuns_contentHeader"
import { graphql } from "gatsby"

export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
  }
`

const leitBildPage = ({data}) => (
  <Layout>
    <SEO title="Ansprechpartner"/>

    <WirUeberUnsContentHeader headline="UNSER LEITBILD" motto={data.allSanitySiteSettings.nodes[0].motto} />

    <section id="content_2_schule">
      <WirUeberUnsSubmenu />

      <article id="con_2_rechts">
        <h3 className="small_sub">
          <span style={{color: "007c34"}}>UNSER LEITBILD</span>
        </h3>

        <p>
            Ziel unseres Leitbildes ist die Schaffung einer positiven Lernatmosph&auml;re, in der sich Ihre Kinder
            aufgehoben und geborgen f&uuml;hlen, in der sie wachsen und sich entwickeln k&ouml;nnen.
            <br/>Eine enge Zusammenarbeit zwischen Eltern und Schule ist eine wichtige Voraussetzung f&uuml;r eine erfolgreich verlaufende Schulzeit.
            <br/>
            <br/>Stark f&uuml;r Ihr Kind: Das sind wir nur gemeinsam!
        </p>

        <ul>
          <li className="aufzaehlung_gruener_kreis">Das Wohl des Kindes steht im Vordergrund.</li>
          <li className="aufzaehlung_gruener_kreis">Die individuelle Entwicklung der Kinder ist unsere Aufgabe.</li>
          <li className="aufzaehlung_gruener_kreis">Wir fordern und f&ouml;rdern.</li>
          <li className="aufzaehlung_gruener_kreis">Unser Ziel ist selbstst&auml;ndiges und selbstt&auml;tiges
            Lernen.
          </li>
        </ul>
        <br/>


        <p className="text">
          <span>Die Basis f&uuml;r Lernen, Arbeiten und Leben an unserer Schule ist:</span>
          <ul>
            <li className="aufzaehlung_gruener_kreis">Offenheit</li>
            <li className="aufzaehlung_gruener_kreis">Transparenz</li>
            <li className="aufzaehlung_gruener_kreis">Respekt</li>
            <li className="aufzaehlung_gruener_kreis">Verst&auml;ndnis f&uuml;reinander</li>
          </ul>
        </p>
        <br/>
        <ul>
          <li className="aufzaehlung_gruener_kreis">Wir f&uuml;hren regelm&auml;&szlig;ig Elterngespr&auml;che.
          </li>
          <li className="aufzaehlung_gruener_kreis">Wir leben gegenseitige Wertsch&auml;tzung durch Akzeptanz und
            Toleranz.
          </li>
          <li className="aufzaehlung_gruener_kreis">Wir sind offen f&uuml;r Neues und reflektieren
            Altbew&auml;hrtes.
          </li>
        </ul>
        <br/>
        <br/>

        <h3 className="small_sub">
          <span style={{color:"#007c34"}}>Das ganz besondere Angebot</span>
        </h3>

        <p className="text">
            Unser schulisches Angebot richtet sich nach dem individuellen Lern- und
            Entwicklungsbedarf unserer Sch&uuml;lerinnen und Sch&uuml;ler. Zur optimalen Ausbildung und
            Unterst&uuml;tzung
            Ihrer Kinder bieten wir Ihnen zus&auml;tzlich zu den &uuml;blichen Inhalten und Einrichtungen an
            unserer Schule folgende Besonderheiten:
        </p>
        <br/><br/>
        <ul>
          <li className="aufzaehlung_gruener_kreis">Klassenlehrerteam: 2 Klassenlehrer pro Klasse</li>
          <li className="aufzaehlung_gruener_kreis">F&ouml;rdern und Fordern:</li>
          <li className="aufzaehlung_gruener_kreis_einzug">Individuelle F&ouml;rderung f&uuml;r den 1./2.
            Jahrgang
            im &quot;Lernstudio&quot;</li>
          <li className="aufzaehlung_gruener_kreis_einzug">Auffangklasse f&uuml;r Kinder mit geringen bzw.
            keinen
            Deutschkenntnissen
          </li>
          <li className="aufzaehlung_gruener_kreis_einzug">Sprachf&ouml;rderung &bdquo;Deutsch als
            Zweitsprache&ldquo;
            (DaZ)
          </li>
          <li className="aufzaehlung_gruener_kreis_einzug">F&ouml;rderung und St&auml;rkung der
            Lesekompetenz
          </li>
          <li className="aufzaehlung_gruener_kreis_einzug">Lernstationen &ndash; individuelle F&ouml;rderung
            durch
            &bdquo;Bildung
            und Teilhabe&ldquo;
          </li>
          <li className="aufzaehlung_gruener_kreis_einzug">Lesepatenschaft mit Sch&uuml;lern der 8. Klassen
            des
            St&auml;dt.
            Gymnasiums L&ouml;hne
          </li>
          <li className="aufzaehlung_gruener_kreis_einzug">St&auml;rkung der Medienkompetenz</li>
          <li className="aufzaehlung_gruener_kreis_einzug">Zus&auml;tzliche F&ouml;rderung und St&auml;rkung
            der
            Sozialkompetenz
          </li>
          <li className="aufzaehlung_gruener_kreis">Projekt &bdquo;Klasse
            2000&ldquo; (Gesundheitsf&ouml;rderung)
          </li>
          <li className="aufzaehlung_gruener_kreis">Projekt &bdquo;Kultur und Schule&ldquo; mit
            Teampartnern:</li>
          <li className="aufzaehlung_gruener_kreis_einzug">Musikschule</li>
          <li className="aufzaehlung_gruener_kreis_einzug">Jugendkunstschule</li>
          <li className="aufzaehlung_gruener_kreis_einzug">Stadtb&uuml;cherei</li>
          <li className="aufzaehlung_gruener_kreis_einzug">Heimatmuseum</li>
          <li className="aufzaehlung_gruener_kreis_einzug">Volkshochschule</li>
          <li className="aufzaehlung_gruener_kreis">Zahnprojekt</li>
        </ul>
        <br/>

        <p className="text">
          <span style={{fontWeight: "bold"}}>Offene Ganztagsschule:</span> 11.30 Uhr &ndash; 16.30 Uhr<br/>
          <span style={{fontWeight: "bold"}}>Betreuung: </span>07.00 Uhr &ndash; 14.30 Uhr<br/><br/>
          <em>F&uuml;r weitere Informationen sprechen Sie uns gern an!</em>
        </p>

      </article>
      <TermineMargin />


    </section>
  </Layout>
)

export default leitBildPage
